@mixin maxLineText($numberOfLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLine;
  -webkit-box-orient: vertical;
}

@mixin boderVideo($boder-radius) {
  .video-react {
    border-radius: $boder-radius !important;
    background-color: $color-white;

    .video-react-video {
      border-radius: $boder-radius !important;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  .video-react-poster {
    border-radius: $boder-radius;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.3);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.6);
    opacity: 0.6;
  }

  75% {
    -webkit-transform: scale(0.9);
    opacity: 0.3;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.3);
    opacity: 1;
  }

  50% {
    transform: scale(0.6);
    opacity: 0.6;
  }

  75% {
    transform: scale(0.9);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@mixin aspect-ratio-box($ratio) {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: $ratio;
}

// $breakpoints: (
//   sm: 600px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px,

//   'small':  600px,
//   'medium': 768px,
//   'large':  992px
// );

// Extra small screen / phone
$screen-xs: 600px;

// Small screen / tablet
$screen-sm: 768px;

// Medium screen / desktop
$screen-md: 992px;

// Large screen / wide desktop
$screen-lg: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);

// respond-to()
// ------------
@mixin media-screen($media) {
  @if $media==xs {
    @media (max-width: $screen-xs-max) {
      @content
    }
  }

  @else if $media==sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @content
    }
  }

  @else if $media==md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @content
    }
  }

  @else if $media==lg {
    @media (min-width: $screen-lg) {
      @content
    }
  }
}