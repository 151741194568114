.btn-large {
  width: 311px;
}

.btn,
.ant-btn {
  height: 44px;

  border-width: 1px;
  border-style: solid;
  border-color: $color-primary;
  border-radius: 22px;
  background: $color-white;
  color: $color-primary;
  text-align: center;
  font: normal normal normal 16px/20px $font-family;
  text-shadow: none;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    border-width: 1px;
    border-style: solid;
    border-color: $color-primary;
    border-radius: 22px;
    background: $color-white;
    color: $color-primary;
  }

  &-primary {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 22px;
    background: transparent linear-gradient(0deg, #99C81C 0%, #AED641 57%, #BDE05D 100%) 0% 0% no-repeat padding-box;
    color: $color-white;
    text-align: center;
    font: normal normal normal 16px/20px $font-family;
    text-shadow: none;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      border: none;
      border-color: transparent;
      border-radius: 22px;
      background: transparent linear-gradient(0deg, #99C81C 0%, #AED641 57%, #BDE05D 100%) 0% 0% no-repeat padding-box;
      color: $color-white;
    }
  }

  &-primary[disabled],
  &-primary[disabled]:hover,
  &-primary[disabled]:focus,
  &-primary[disabled]:active {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 22px;
    background: transparent linear-gradient(0deg, #99C81C 0%, #AED641 57%, #BDE05D 100%) 0% 0% no-repeat padding-box;
    color: $color-white;
    opacity: 0.5;
  }

  &-dashed {
    border-style: dashed;

    &:hover,
    &:active,
    &:focus {
      border-style: dashed;
    }
  }

  &-secondary {
    background: linear-gradient(11.97deg, #103393 -0.51%, #3481ff 81.1%, #00d4ff 170.56%);
    font-family: Roboto-bold;

    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(11.97deg, darken($color: #103393, $amount: 6%) -0.51%, darken($color: #3481ff, $amount: 6%) 81.1%, darken($color: #00d4ff, $amount: 6%) 170.56%);
      color: $color-white
    }
  }

  &-dangerous,
  &-danger {
    border-color: $color-danger;
    background: $color-white;
    color: $color-danger;

    &:hover,
    &:active,
    &:focus {
      border-color: $color-danger;
      background: $color-white;
      color: $color-danger;
    }

    &.ant-btn-text,
    &.ant-btn-text:hover,
    &.ant-btn-text:focus {
      color: $color-danger;
      border-color: transparent;
      background: transparent;
    }

    &.ant-btn-link,
    &.ant-btn-link:hover,
    &.ant-btn-link:focus {
      color: $color-danger;
      border-color: transparent;
      background: transparent;
      box-shadow: none;
    }
  }

  &-text {
    border: none;
    border-color: transparent;
    background: transparent;
    width: unset;
    height: unset;
    display: flex;
    padding: 0;
    transition: none;
    color: $color-dark;

    &:hover,
    &:active,
    &:focus {
      border: none;
      border-color: transparent;
      background: transparent;
      color: $color-dark;
    }
  }

  &-link {
    border: none;
    border-color: transparent;
    background: transparent;
    width: unset;
    height: unset;
    display: flex;
    padding: 0;
    transition: none;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      border: none;
      border-color: transparent;
      background: transparent;
      text-decoration: underline;
    }
  }

  &.link-small {
    font: normal normal normal 12px/20px $font-family;
  }

  &-icon-only {
    border: none;
    border-color: transparent;
    background: transparent;
    width: unset;
    height: unset;
    display: flex;
    padding: 0;
    transition: none;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      border: none;
      border-color: transparent;
      background: transparent;
      color: transparent;
      text-decoration: none;
    }

  }

  &-green {
    border: 1px solid $border-primary;
    background: $bg-secondary;
    color: $color-primary;
    font-family: Roboto-bold;
  }

  &--tag {
    padding-left: 3.3rem;
    padding-right: 3.3rem;
    color: $color-secondary;
    background-color: $bg-primary;
    font-family: Roboto-medium;

    svg {
      margin-right: 8px;
    }
  }
}