.ant-page-header {
  &-content {
    @extend .pa-0;
    width: 100%;
    text-align: center;
  }

  &-heading-title {
    font: $font-h2;
    color: $text-secondary;
    text-align: center;
    @include media-screen(xs) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-heading-sub-title {
    font: $font-subtitle;
    color: $text-grey;
    text-align: center;
  }
}