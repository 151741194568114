.custom-tabs {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 60px;
}

.custom-tabs>.custom-tabs-nav,
.custom-tabs>div>.custom-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}

.custom-tabs>.custom-tabs-nav .custom-tabs-nav-wrap,
.custom-tabs>div>.custom-tabs-nav .custom-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}

.custom-tabs>.custom-tabs-nav .custom-tabs-nav-list,
.custom-tabs>div>.custom-tabs-nav .custom-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}

.custom-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  // padding: 12px 0;
  background: transparent;
  border-bottom: 3px solid transparent;
  outline: none;
  cursor: pointer;


  &.custom-tabs-tab-active {
    border-color: $color-secondary;
  }
}

.custom-tabs-tab+.custom-tabs-tab {
  margin: 0 0 0 10%;
}

.custom-tabs-tab-btn,
.custom-tabs-tab-btn span {
  outline: none;
  transition: all 0.3s;
  text-align: center;
  font: normal normal normal 18px/22px $font-family;
  color: #777777;
}

.custom-tabs-tab.custom-tabs-tab-active .custom-tabs-tab-btn,
.custom-tabs-tab.custom-tabs-tab-active .custom-tabs-tab-btn span {
  color: #E75012;
  text-align: center;
  font: normal normal 600 18px/22px $font-family-semibold;
}