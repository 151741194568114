@font-face {
    font-family: 'proxima-nova-bold';
    src: url('ProximaNova-Bold.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: 'proxima-nova';
    src: url('ProximaNova-Regular.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: 'proxima-nova-semibold';
    src: url('ProximaNova-Semibold.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: 'proxima-nova-medium';
    src: url('ProximaNova-Medium.otf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}