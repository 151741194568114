.ant-input {
  border-color: transparent;
  background: #FFFFFF;
  box-shadow: 0px 1px 6px #00000029;
  // border-radius: 5px;
  color: $text-grey;
  font: $font-input;
  // padding: 10px 18px;

  &::placeholder {
    color: $text-grey;
    font-style: italic;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
  }

  &:focus {
    border-color: transparent;
    outline: none !important;
    box-shadow: none !important;
  }
}

textarea:focus {
  border-color: transparent;
  outline: none !important;
}



.ant-input-affix-wrapper {
  outline: none !important;
  border: none;
  border-color: transparent;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 5px;
  padding: 10px 12px 10px 18px;
  color: $text-grey;
  font: $font-input;
}

.ant-input-suffix {
  border-left: 1px solid #DEDEDE;
  padding-left: 12px;
  height: 24px;
}





.no-border {
  .ant-input,
  .ant-input-affix-wrapper {
    border: none;
    border-color: transparent;
    border-radius: unset;
    outline: none !important;
    box-shadow: none;
  }
}


.ant-input-wrapper {
  border-radius: 5px;
  overflow: hidden;
}

.form-item-wrapper {
  background: $color-white;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 5px;

  .ant-input {
    border-radius: 5px;
  }

  .ant-input-group-addon {
    background: $color-white;
    border: none;
    border-radius: 5px;
  }

  .anticon.anticon-search {
    @extend .ma-2;
    @extend .pl-3;
    font-size: 24px;
    color: $color-primary;
    border-left: 1px solid #d9d9d9;
  }
}


.ant-input-prefix {
  @extend .mr-2;
}



@media (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label {
    flex: unset;
    margin-right: 12px;
  }

  .ant-form .ant-form-item .ant-form-item-control {
    flex: 1;
  }
}


