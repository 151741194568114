.commande {
  display: grid;
  grid-template-columns: auto;
  gap: 20px;
  max-width: 520px;
  margin: 0 auto;

  &__title {
    font: normal normal normal 24px/29px $font-family-semibold;
    color: $text-dark
  }

  &__subtitle {
    font: normal normal normal 18px/22px $font-family;
    color: $text-dark
  }

  &__card {
    background-color: $color-white;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 5px;
    padding: 0;

    &-content {
      padding: 12px 12px;
    }

    &-title {
      font: normal normal normal 24px/29px $font-family-semibold;
      color: $text-dark
    }

    &-subtitle {
      font: normal normal normal 600 14px/20px $font-family-semibold;
      color: $text-secondary
    }

  }

  &-filtrer {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    max-width: 690px;
    margin: 0 auto;

    @include media-screen(xs) {
      grid-template-columns: auto;
    }

    &__card {
      background-color: $color-white;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 5px;
      padding: 0;

      &-content {
        padding: 20px 12px;
      }
    }

    &__title {
      font: normal normal normal 16px/18px $font-family-semibold;
      color: $text-secondary;
    }

    &__checkbox {
      .ant-form-item-control-input-content {
        @extend .grid-container;

        .ant-checkbox-wrapper {
          padding: 12px;
          margin-right: 0;
          width: 345px;

          @include media-screen(xs) {
            width: 100%;
          }
        }

        .ant-checkbox-wrapper+.ant-checkbox-wrapper {
          margin-left: 0;
        }

        .ant-checkbox-wrapper:first-child {
          border-bottom: 1px solid #D5D5D5;

        }

        .ant-checkbox-wrapper:nth-child(2) {
          border-left: 1px solid #D5D5D5;
          border-bottom: 1px solid #D5D5D5;

          @include media-screen(xs) {
            border-left: none;
          }
        }

        .ant-checkbox-wrapper:nth-child(3) {
          @include media-screen(xs) {
            border-bottom: 1px solid #D5D5D5;
          }
        }

        .ant-checkbox-wrapper:last-child {
          border-left: 1px solid #D5D5D5;

          @include media-screen(xs) {
            border-left: none;
            border-bottom: none;
          }
        }
      }
    }


  }


}

.grid-container {
  display: grid;
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
  grid-template-columns: auto auto;

  @include media-screen(xs) {
    grid-template-columns: auto;
  }

}