.header {
  // background-color: $bg-primary;
  @extend .px-4;
  @extend .py-0;
  max-width: $content-width;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__wrapper {
    background-color: $bg-dark;
    height: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 99;
  }

  &__menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &-popover {
    background-color: $bg-dark;
    // max-width: 375px;
    width: 319px;

    .profile {
      @extend .pb-12;

      &__name {
        text-align: right;
        font: normal normal bold 22px/27px $font-family-semibold;
        letter-spacing: 0px;
        color: #95C515;
      }

      &__title {
        text-align: right;
        font: normal normal 600 14px/17px $font-family;
        letter-spacing: -0.42px;
        color: #FFFFFF;
      }
    }
  }

}

.header-navbar {
  background-color: $bg-dark;

  &__icon {
    @extend .ml-8;
  }
}

.ant-menu-item,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  border-top: 1px solid $border-grey;
  width: calc(100%);
  padding: 0 !important;
  margin: 0 !important;
}

.ant-menu-inline>.ant-menu-item {
  height: 77px;
  line-height: 77px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
  border-color: transparent;
}

.ant-menu-item,
.ant-menu-item a,
.ant-menu-item a:hover,
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  font: normal normal normal 18px/22px $font-family;
  letter-spacing: -0.54px;
  color: $color-white;
}

.ant-menu-item-disabled,
.ant-menu-item-disabled a {
  color: $text-grey !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
  border-color: transparent;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $color-white !important;
}

.page-title {
  @extend .px-4;
  @extend .py-0;
  max-width: $content-width;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__wrapper {
    background-color: $color-white;
  }

  &__content {
    width: 100%;
    height: 60px;
  }

}

.page-title__btn-back,
.btn-back {
  @extend .pa-0;
  text-align: center;
  letter-spacing: 0px;
  color: $text-dark;
  display: flex;
  align-items: center;
  font: normal normal normal 16px/20px $font-family;
  margin-left: -7px;

  &:hover {
    color: $text-dark
  }
}


.page-title__steps {
  display: block;

  @include media-screen(xs) {
    display: none;
  }
}

.page-title__steps--small {
  display: none;
  text-align: right;

  @include media-screen(xs) {
    display: block;
  }

  .page-titl__steps-icon {
    width: 48px;
    height: 48px;
  }
}