.ant-modal-content {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

.ant-modal-body {
  padding: 0;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw);
    width: calc(100vw);
    padding: 0 16px;
  }
}